import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export function LoginLogout() {
    const { isAuthenticated, isLoading, logout } = useAuth0();
    const isLoggedIn = !isLoading && isAuthenticated;

    if (!isLoading && isLoggedIn) {
        return <><a href="#" onClick={() => logout({ returnTo: window.location.origin })}>Log out</a></>
    }
    else {
        return LoginSignup()
    }
}

export const LoginSignup = () => {
    const { loginWithRedirect } = useAuth0();
    
    return <><a onClick={() => loginWithRedirect()} href="/login">Sign up or log in</a></>
}
