import { useAuth0 } from "@auth0/auth0-react";

import Head from 'next/head'
import Link from 'next/link'

import styles from '../styles/Home.module.css'
import { LoginLogout, LoginSignup } from '../components/loginLogout'

export default function Home() {
  const { isAuthenticated } = useAuth0();

  // This needs isLoading, but that seems weird
  const actionCall = isAuthenticated ? <Link href="/new_short" onClick={() => {}}>Let's go!</Link> : LoginSignup()

  return (
    <div className={styles.container}>
      <Head>
        <title>Simple shorts - hello!</title>
        <meta name="description" content="Simple URL" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <header className={styles.header}>
        <LoginLogout />
      </header>

      <main className={styles.main}>
        <h1 className={styles.title}>
          Simple Shorts
        </h1>

        <p className={styles.description}>
          Simple URL shortening
        </p>
        

        <p>{actionCall}</p>
      </main>

      <footer className={styles.footer}>
        Copyright Simpleshorts 2021
      </footer>
    </div>
  )
}
